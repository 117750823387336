import Helmet from 'react-helmet';
import React from 'react';

import { GatsbyPageProps } from '@/types/Gatsby';
import { JobEntry } from '@/types/Job';
import Button from '../components/Button';
import Container from '../components/Container';
import FeaturesContext from '../contexts/FeaturesContext';
import Grid from '../components/Grid';
import Headline from '../components/Headline';
import Layout from '../components/Layout';
import RichText from '../components/RichText';
import Row from '../components/Row';

import classNames from 'classnames/bind';
import style from './Job.module.scss';
const cx = classNames.bind(style);

export const Post: React.FC<GatsbyPageProps<JobEntry>> = (props) => {
    const { title, location, category, type, body, browserTitle, applyLink } = props.pageContext.entry.fields;

    return (
        <FeaturesContext.Provider value={props.pageContext.features || []}>
            <Layout>
                <Helmet title={browserTitle || title} />
                <Container>
                    <Grid>
                        <article className={cx('job')}>
                            <header className={cx('job-header')}>
                                <Headline className={cx('title')}>{title}</Headline>
                                <p className={cx('job-meta')}>
                                    {location && <span>{location}</span>}
                                    {category && <span>{category}</span>}
                                    {type && <span>{type}</span>}
                                </p>
                                {applyLink && (
                                    <Row className={cx('apply-cta')}>
                                        <Button href={applyLink} color="blue">
                                            Apply for this job
                                        </Button>
                                    </Row>
                                )}
                            </header>
                            <main className={cx('job-body')}>{body && <RichText document={body} />}</main>
                            <footer className={cx('job-actions')}>
                                {applyLink && (
                                    <Button href={applyLink} color="blue">
                                        Apply Now
                                    </Button>
                                )}
                            </footer>
                        </article>
                    </Grid>
                </Container>
            </Layout>
        </FeaturesContext.Provider>
    );
};

export default Post;
